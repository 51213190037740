/* eslint-disable react-hooks/exhaustive-deps */
import { mdiRefresh, mdiRestore, mdiClose, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import { useModal } from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
// import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import {
  GetStartAndEndDateOfMonth,
  UTCToLocalDate,
} from "../../../utils/date_util";
import { currencyFormat } from "../../../utils/orders_utils";
import { urls } from "../../../utils/urls";
import {
  AttachmentModel,
  PurchaseOrderModel,
  getFileType,
  po_approval_status,
} from "../model/purchase_order_model";
import axios from "axios";
import { file_dir } from "../../../utils/enums";
import {
  defaulPartyValue,
  PartyModel,
} from "../../accounts/models/party_model";
import PartySearchField from "../../accounts/parties/party_search_field";
import NewTableView, {
  NewTableRow,
} from "../../../procurement/components/new_table_comp";
import DrawerModal, { DrawerModalLoaction } from "../../../ui/new_components/common/drawer_modal";
import ActionHistory from "../purchase_order_create/components/transfer_logs";
interface POApprovalListFilter {
  page: number;
  count: number;
  search?: string;
  fromDate?: Date;
  toDate?: Date;
  party_id?: string;
  party_name?: string;
  approval_status?: string;
}
const defaultFilter = (): POApprovalListFilter => {
  const now = new Date();
  // const { start_date, end_date } = GetStartAndEndDateOfMonth(now);
  const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  const end_date = now;
  return {
    page: 1,
    count: 100,
    fromDate: start_date,
    toDate: end_date,
  };
};
interface PurchaseOrderApprovalListScreenProps {}

const PurchaseOrderApprovalListScreen: FunctionComponent<
  PurchaseOrderApprovalListScreenProps
> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const { showModal, onClose } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [ShowHistory, setShowHistory] = useState<any>();
  const [ShowHistory1, setShowHistory1] = useState<any>();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PurchaseOrderModel[]>([]);

  const tempFilter = localStorage.getItem("po_approval_list_filter");

  const [filter, setFilter] = useState<POApprovalListFilter>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter()
  );
  const fetchData = async (prop: POApprovalListFilter) => {
    setLoading(true);
    try {
      console.log("PO  request",prop);
      const res = await fetchGet(
        url + toQueryString({ ...prop, approval: true })
      );
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };
  const debouncedHandler = useCallback(
    debounce((prop: POApprovalListFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 100),
    []
  );

  useEffect(() => {
    if (tempFilter) {
      const t: POApprovalListFilter = JSON.parse(tempFilter);
      if (t.party_id) {
        const vendor = defaulPartyValue;
        vendor.id = t.party_id;
        vendor.name = t.party_name ?? "";
        setVendor(vendor);
      }
    }
    localStorage.setItem("po_approval_list_filter", JSON.stringify(filter));
    debouncedHandler(filter);
  }, [filter]);
  const [vendor, setVendor] = useState<PartyModel>();
  const [showAttachment, setShowAttachment] = useState<PurchaseOrderModel>();
  const [, setAttachmentKey] = useState(Math.random() * 10000);

  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${showAttachment?.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;

        //   const options = {
        //     method: "PUT",
        //     body: file,
        //     headers: {
        //       "Content-Type": type,
        //     },
        //   };
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });

        const attachment: AttachmentModel = {
          id: "",
          category,
          type: getFileType(type),
          url,
        };
        const create_res = await fetchPost(create_url, attachment);
        console.log({ create_res });
        if (create_res.success) {
          showToast({
            type: ToastType.success,
            text: create_res.message,
          });
          setData((o) => [
            ...o.map((f) => {
              if (f.id === showAttachment)
                return {
                  ...f,
                  attachments: [...(f.attachments || []), create_res.data],
                };
              return f;
            }),
          ]);

          removeSelectedFile();
          setShowUpload(false);
          setAttachmentKey(Math.random() * 1000);
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${showAttachment?.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setData((o) => [
          ...o.map((f) => {
            if (f.id == showAttachment) {
              f.attachments = f.attachments.filter((f) => f.id !== att.id);
            }
            return f;
          }),
        ]);

        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  return (
    <>
     {ShowHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={ShowHistory !== undefined}
          onClose={() => setShowHistory(undefined)}
          title="History"
        >
          {/* {console.log("id of order", ShowHistory)} */}
          <ActionHistory
            po_no={ShowHistory}
            data={ShowHistory1}
            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          />
        </DrawerModal>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                Purchase orders approval
              </p>
              <button title="Refersh" onClick={() => debouncedHandler(filter)}>
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end   text-sm items-center gap-1 ">
              <SearchFieldWidget
                defaulValue={filter.search}
                onClear={() => {
                  setFilter((o) => ({ ...o, search: "", page: 1 }));
                }}
                onSubmit={(val) => {
                  setFilter((o) => ({
                    ...o,
                    search: val,
                    page: 1,
                  }));

                  // setTimeout(() => {
                  //   setSearch(val);
                  // }, 10);
                }}
              />
              {/* <button
                onClick={() => setFilter(defaultFilter())}
                className="flex gap-1 items-center cursor-pointer rounded p-1 border border-myPrimaryColor hover:scale-105 duration-200  text-myPrimaryColor  transform"
              >
                <Icon path={mdiRestore} size={0.7}></Icon>Reset filter
              </button> */}
              {/* <div
                onClick={() => {
                  navaigation.push(urls.purchase_order + "/approval/:id");

                  // showModal({
                  //   title: `Add item unit`,
                  //   type: ModalType.modal,
                  //   container(id) {
                  //     return (
                  //       <ItemUnitCreateScreen
                  //         onClose={(d) => {
                  //           if (d)
                  //             setData((o) => {
                  //               o.pop();
                  //               return [d, ...o];
                  //             });
                  //           setTotal((o) => o + 1);
                  //           onClose(id);
                  //         }}
                  //       />
                  //     );
                  //   },
                  // });
                }}
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div> */}
            </div>
          </div>
          <div className="py-2">
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  name=""
                  id="fromDate"
                  className="p-1 rounded focus:outline-none"
                  value={
                    filter.fromDate
                      ? moment(filter.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const fromDate = new Date(
                      new Date(now).setHours(0, 0, 0, 0)
                    );
                    setFilter((o) => ({ ...o, fromDate }));
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  name=""
                  id="toDate"
                  className="p-1 rounded focus:outline-none "
                  value={
                    filter.toDate
                      ? moment(filter.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const now = UTCToLocalDate(e.target.value)!;
                    const toDate = new Date(
                      new Date(now).setHours(23, 59, 59, 999)
                    );
                    setFilter((o) => ({ ...o, toDate }));
                  }}
                />
              </div>
              {(filter.fromDate || filter.toDate) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((o) => ({
                      ...o,
                      fromDate: undefined,
                      toDate: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
              <div className="flex items-center  gap-1">
                <label htmlFor="app_status" className="">
                  Approval status
                </label>
                <select
                  className="p-1 rounded focus:outline-none"
                  name=""
                  id="app_status"
                  value={filter.approval_status}
                  onChange={(e) => {
                    setFilter((o) => ({
                      ...o,
                      approval_status: e.target.value,
                    }));
                  }}
                >
                  <option value={undefined}>select</option>
                  {Object.values(po_approval_status).map((f) => (
                    <option key={f} value={f}>
                      {f}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="" className="">
                  Vendor
                </label>
                <div className="w-96 flex ">
                  <PartySearchField
                    show_add={false}
                    placeholder="Select vendor"
                    onSelect={(d) => {
                      setFilter((o) => ({
                        ...o,
                        party_id: d?.id ?? undefined,
                        party_name: d?.name ?? undefined,
                      }));
                      setVendor(d);
                    }}
                    value={vendor}
                  />
                  {filter.party_id && (
                    <button
                      title="Clear vendor"
                      onClick={() =>
                        setFilter((o) => ({ ...o, party_id: undefined }))
                      }
                      className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform bg-white"
                    >
                      <Icon path={mdiClose} size={0.7}></Icon>
                    </button>
                  )}
                  <button
                    title="Reset filters"
                    onClick={() => setFilter(defaultFilter())}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform bg-white"
                  >
                    <Icon path={mdiRestore} size={0.7}></Icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>

        {/* <TableView
          headers={[
            {
              key: "po_no",
              title: "PO #",
              type: FieldType.string,
            },
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
            },
            {
              key: "delivery_date",
              title: "Delivery date",
              type: FieldType.date,
            },
            {
              key: "item",
              title: "Item",
              type: FieldType.string,
            },
            {
              key: "party",
              title: "Party",
              type: FieldType.string,
            },
            {
              key: "",
              title: "Responsible",
              type: FieldType.string,
            },
            {
              key: "warehouse",
              title: "Warehouse",
              type: FieldType.string,
            },

            {
              key: "approval_status",
              title: "Approval status",
              type: FieldType.string,
            },

            {
              key: "status",
              title: "Status",
              type: FieldType.string,
            },
            {
              key: "total",
              title: "Total",
              type: FieldType.currency,
            },
          ]}
          rows={data.map((data, i) => ({
            data,
            values: [
              data.po_no,
              data.date,
              data.delivery_date,
              `${data.items[0].item.name} ${
                data.items.length > 1
                  ? `... and   ${data.items.length - 1} more`
                  : ""
              }`,
              data.party.name,
              data.shipping_profile.contact_name,
              data.warehouse.name,
              data.approval_level == data.current_approval_level &&
              (data.current_approval_count ?? 0) >=
                (data.current_approval_required_count ?? 0)
                ? "Approved"
                : (data.current_approval_required_count ?? 0) > 0 &&
                  (data.current_reject_count ?? 0) -
                    (data.current_approval_count ?? 0) >=
                    (data.current_approval_required_count ?? 0)
                ? "Rejected"
                : "Pending",

              data.status,
              currencyFormat(data.total),
            ],
          }))}
          page={filter.page}
          total={total}
          count={filter.count}
          itemCount={data.length}
          onPageChange={(page) => {
            setFilter((o) => ({ ...o, page }));
          }}
          onClick={onSelect}
        /> */}
        <NewTableView
          stickyHeader={true}
          show_index={true}
          show_pagination={true}
          headers={[
            {
              key: "po_no",
              value: "PO #",
              type: FieldType.string,
            },
            {
              key: "project_name",
              value: "Project",
              type: FieldType.string,
            },
            {
              key: "date",
              value: "Date",
              type: FieldType.date,
            },
            {
              key: "delivery_date",
              value: "Delivery date",
              type: FieldType.date,
            },
            {
              key: "item",
              value: "Item",
              type: FieldType.string,
            },
            {
              key: "party",
              value: "Party",
              type: FieldType.string,
            },
            {
              key: "",
              value: "Responsible",
              type: FieldType.string,
            },
            {
              key: "warehouse",
              value: "Warehouse",
              type: FieldType.string,
            },

            {
              key: "approval_status",
              value: "Approval status",
              type: FieldType.string,
            },

            {
              key: "status",
              value: "Status",
              type: FieldType.string,
            },
            {
              key: "total",
              value: "Total",
              type: FieldType.currency,
            },
            {
              key: "",
              value: "Attachment",
              type: FieldType.string,
            },
          ]}
          rows={data.map((data, i) => {
            const row: NewTableRow<PurchaseOrderModel> = {
              data: data,

              cells: [
                {
                  value: data.po_no,
                  builder: () => (
                    <div className="flex items-center justify-start gap-1 p-1 h-full sticky-field whitespace-nowrap">
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(data.po_no);
                          showToast({
                            type: ToastType.info,
                            text: "Copied PO NO to clip board!",
                          });
                        }}
                      >
                        <Icon path={mdiContentCopy} size={0.5}></Icon>
                      </button>
                      #{data.po_no}
                    </div>
                  ),
                },
                { value: data.project },
                { value: data.date },
                { value: data.delivery_date },
                {
                  builder: () => (
                    <a
                      href={urls.purchase_order + "/approval/" + data.id}
                      className="px-2 text-left text-blue-400 hover:text-blue-500 sticky-field h-full w-full"
                    >
                      {`${data.items[0].item.name} ${
                        data.items.length > 1
                          ? `... and   ${data.items.length - 1} more`
                          : ""
                      }`}
                    </a>
                  ),
                  title: `${data.items.reduce(
                    (p, v) => p + v.item.name + " | ",
                    ""
                  )}  `,
                },
                { value: data.party.name },
                { value: data.shipping_profile.contact_name },
                { value: data.warehouse.name },
                {
                  value:
                    data.approval_level === data.current_approval_level &&
                    (data.current_approval_count ?? 0) >=
                      (data.current_approval_required_count ?? 0)
                      ? "Approved"
                      : (data.current_approval_required_count ?? 0) > 0 &&
                        (data.current_reject_count ?? 0) -
                          (data.current_approval_count ?? 0) >=
                          (data.current_approval_required_count ?? 0)
                      ? "Rejected"
                      : "Pending",
                },
                { value: data.status },
                {
                  value: currencyFormat(
                    data.total,
                    data?.vendor_currency?.short_name
                  ),
                },
                {
                  builder: () => (
                    <button
                      onClick={() => setShowAttachment(data)}
                      className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field  flex items-center gap-1 px-2 h-full w-full"
                    >
                      View
                      <div>({data.attachments.length})</div>
                    </button>
                  ),
                },
              ],
            };
            return row;
          })}
          page={filter.page}
          onShowHistory={(data) => {
            setShowHistory(data.po_no);
            setShowHistory1(data);
          }}
          total={total}
          count={filter.count}
          itemCount={data.length}
          onPageChange={(page) => {
            setFilter((o) => ({ ...o, page }));
          }}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PurchaseOrderApprovalListScreen;
