import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import ProjectCreateScreen from "../projects/project_master/project_create_screen";
import ProjectListScreen from "../projects/project_master/project_list_screen";

const project_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project,
    component: ProjectListScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project,
    component: ProjectListScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project + "/create",
    component: ProjectCreateScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_project + "/update/:id",
    component: ProjectCreateScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  // {
  //   isPrivate: true,
  //   fullScreen: false,
  //   exact: true,
  //   path: urls.procurement_project + "/update/:id",
  //   component: ProjectApprovalScreen,
  //   module: MODULE.purchase_order,
  //   main_module: MODULE.procurement_panel,
  //   action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
  //   operator: "or",
  // },
];

export default project_routes;
