import { mdiCheck, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import ItemSearchField from "../../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
  ItemStatus,
  StockMovementType,
} from "../../../models/inventory_voucher";
import { ItemModel } from "../../../models/item_model";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import { WAREHOUSE_TYPE } from "../../../models/warehouse_model";
import moment from "moment";
import ProjectSearchField from "../../../../purchase_order/projects/project_master/project_search_field";

interface ItemRowProps {
  max_qty?: number;
  price?: number;
  index: number;
  item?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
  remainingUnits?: number;
  onAddRow?: (item: ItemMovementModel) => void;
}

const ItemRow: FunctionComponent<ItemRowProps> = (props) => {
  const [data, setData] = useState<ItemMovementModel>({
    ...itemMovementDefaultValue,
  });
  const { showToast } = useToast();
  const { edit, onSubmit, remainingUnits } = props;
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (remainingUnits !== undefined) {
      setData((prevData) => ({
        ...prevData,
        received_unit_no: remainingUnits,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingUnits]);

  useEffect(() => {
    if (props.item) {
      setData((prevData) => ({
        ...prevData,
        ...props.item,
      }));
    }
  }, [props.item]);

  useEffect(() => {
    setEditable(edit);
  }, [edit]);

  useEffect(() => {
    let newStatus = data.status;
    if (data.received_unit_no === 0) {
      newStatus = ItemStatus.in_transit;
    } else if (data.received_unit_no < data.unit_no) {
      newStatus = ItemStatus.partialReceipt;
    } else if (data.received_unit_no === data.unit_no) {
      newStatus = ItemStatus.completed;
    }
    setData((prevData) => ({ ...prevData, status: newStatus }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.received_unit_no, data.unit_no]);

  const onDone = () => {
    if (!data.projectTo) {
      showToast({
        type: ToastType.warning,
        text: "Please select Project To and Quantity",
      });
      return;
    }
    if (data.received_unit_no + data.lost_unit_no > data.unit_no) {
      showToast({
        type: ToastType.warning,
        text: "Please enter correct units that received.",
      });
      return;
    }
    onSubmit(data);
  };

  const getProjectDisplayText = () => {
    const projects = [...new Set(data.projectFrom || [])];
    if (projects.length === 0) return "";
    if (projects.length === 1) return projects[0];
    return `${projects[0]} + ${projects.length - 1} more`;
  };

  const handleReceivedUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value) || 0;
    if (newValue <= data.unit_no) {
      setData((prevData) => ({ ...prevData, received_unit_no: newValue }));
    } else {
      showToast({
        type: ToastType.warning,
        text: "Please enter correct quantity received.",
      });
      return;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onDone();
      }}
      className="bg-white p-1"
    >
      <div className="grid grid-cols-12 gap-1 items-center col-span-1">
        <div className="col-span-1">
          <input
            value={data?.date ? moment(data.date).format("YYYY-MM-DD") : ""}
            disabled
            type="date"
            className="text-center p-1 focus:outline-none border rounded w-full"
          />
        </div>
        <div className="col-span-1">
          <ItemSearchField
            disabled
            value={data.item}
            onSelect={(item: ItemModel) => {
              setData((prevData) => ({
                ...prevData,
                item: item,
                item_id: item.id,
              }));
            }}
          />
        </div>
        <div className="">
          <WarehouseSearchField
            type={WAREHOUSE_TYPE.warehouse}
            disabled
            value={data.warehouse}
            onSelect={(d) => {
              setData((old) => ({ ...old, warehouse: d }));
            }}
          />
        </div>
        <div className="col-span-1 flex items-center">
          <input
            value={data?.unit_no}
            disabled
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />
        </div>
        {/* {console.log("Data Id",data.id)} */}
        <div className="col-span-1">
          <input
            value={data.received_unit_no || 0}
            onChange={handleReceivedUnitChange}
            disabled={data.movement_type === StockMovementType.inward} // Always editable
            type="text"
            className="text-center p-1 focus:outline-none border rounded w-full"
          />
        </div>

        <div className="col-span-1">
          <input
            value={getProjectDisplayText()}
            disabled={!editable || props.disabled}
            placeholder="project"
            type="text"
            className="text-center p-1 focus:outline-none border rounded w-full"
          />
        </div>
        <div className="col-span-1">
          <input
            value={data.lost_unit_no || 0}
            disabled={data.movement_type === StockMovementType.inward}
            onChange={(e) => {
              let lost_units = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                lost_units = Number(e.target.value);
              }
              setData((prevData) => ({
                ...prevData,
                lost_unit_no: lost_units,
              }));
            }}
            placeholder=""
            type="text"
            className="text-center p-1 focus:outline-none border rounded w-full"
          />
        </div>
        <div className="col-span-1">
          <input
            value={Math.round(data.price)}
            disabled
            type="text"
            className="text-center p-1 focus:outline-none border rounded w-full"
            readOnly
          />
        </div>
        <div className="col-span-1">
          <input
            value={
              // data.received_unit_no ?
              Math.round(data.received_unit_no * data.price)
              // : data.unit_no * data.price
            }
            disabled
            type="text"
            className="text-right p-1 focus:outline-none border rounded w-full"
          />
        </div>
        <div className="col-span-1">
          <ProjectSearchField
            disabled={false} // Always editable
            placeholder="Select Project"
            value={data.projectTo}
            onSelect={(d) => {
              setData((prevData) => ({
                ...prevData,
                projectTo: {
                  ...prevData.projectTo!,
                  name: d.name,
                  description: d.description,
                  amount: d.amount,
                  spent: d.spent,
                  // batch_name: d.batch_name,
                  // batch_size: d.batch_size,
                  // category: d.category,
                },
              }));
            }}
          />
        </div>

        <div className="flex flex-col mb-3 pt-3 items-start text-sm w-full">
          <select
            value={data.status}
            disabled={editable}
            className="p-1.5 rounded focus:outline-none w-full border"
          >
            <option value="" hidden>
              Select Status
            </option>
            <option value={ItemStatus.in_transit}>In Transit</option>
            <option value={ItemStatus.completed}>Completed</option>
            <option value={ItemStatus.partialReceipt}>
              Partially Received
            </option>
          </select>
        </div>
        <div className="flex gap-1 items-end justify-end">
          {props.item &&
            !props.disabled &&
            data.movement_type === StockMovementType.outward && (
              <>
                <div className="flex justify-center gap-1 items-end">
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure?")) return;
                      onDone();
                      setEditable(false);
                    }}
                    className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiCheck} className="h-5 w-5" />
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure?")) return;
                    if (props.onDelete) props.onDelete(data);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4" />
                </div>
              </>
            )}
        </div>
      </div>
    </form>
  );
};

export default ItemRow;
