/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  payment_request_default,
  PaymentModeOptions,
  PaymentRequestModel,
} from "../../model/payment_request_model";
import { urls } from "../../../../utils/urls";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../../service/api_client";
import LoadingWidget from "../../../../context_providers/modal/loader";
import moment from "moment";
import AttachmentViewer from "../../../../ui/new_components/common/attachment_viewer";
import { PurchaseOrderModel } from "../../model/purchase_order_model";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiHistory, mdiInformationOutline } from "@mdi/js";
import { currencyFormat } from "../../../../utils/orders_utils";
import { ProcurementProjectModel } from "../../projects/model/project_model";
import PoItemRow from "../../purchase_order_create/components/item_row";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import { UTCToLocalDate } from "../../../../utils/date_util";
import {
  getProcurementAttachmentPRESignedURL,
  postProcurementAttachmentS3,
  updatePaymentRequestApi,
} from "../../purchase_order_create/service/purchase_order_repository";
import ActionHistoryAdvance from "../components/payment_request_advance_history_logs";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import ProjectSearchField from "../../projects/project_master/project_search_field";

interface PaymentRequestAdvanceViewProps {
  edit: boolean;
  onClose: (id?: PaymentRequestModel) => void;
  data?: PaymentRequestModel;
}

const PaymentRequestAdvanceScreen: FunctionComponent<
  PaymentRequestAdvanceViewProps
> = (props) => {
  const isUpdatePage = useRouteMatch(
    `${urls.po_advance_payment_request}/updateAdvance/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request`;
  const [showSuccess, setShowSuccess] = useState(false);
  const [percent, setPercent] = useState<number>(0);
  const [amountAdvance, setAmount] = useState<any>();
  const [payAmount, setPayAmount] = useState<number>(0);
  const { showToast } = useToast();
  const [showCreatePaymentRequestModal, setShowCreatePaymentRequestModal] =
    useState(false);
  const [advancePaymentFileList, setAdvancePaymentFileList] = useState<File[]>(
    []
  );
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [project, setProject] = useState<ProcurementProjectModel>();
  const [purchaseOrderData, setPurchaseOrderData] =
    useState<PurchaseOrderModel>();
  const [data, setData] = useState<PaymentRequestModel>(
    props.data || ({} as PaymentRequestModel)
  );
  const existingItems = purchaseOrderData?.items || [];

  const defaultPaymentRequestModel: PaymentRequestModel =
    payment_request_default;
  const { showModal } = useModal();

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  useEffect(() => {
    // Fetch the data for the payment request (when editing or creating)
    if (id && isUpdatePage) {
      setLoading(true);
      getData(id);
      console.log("Updated amountAdvance:", amountAdvance);
    }
  }, [data.id, amountAdvance]);

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      if (data.po_no && !purchaseOrderData) {
        setLoading(true);
        await fetchPurchaseOrderByPoNo(data.po_no);
      }
    };

    fetchPurchaseOrder();
  }, [data.po_no, purchaseOrderData]);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (purchaseOrderData?.project_id && !project) {
        setLoading(true);
        await getProjectData(purchaseOrderData.project_id);
      }
    };

    fetchProjectData();
  }, [purchaseOrderData?.project_id, project]);

  const submit = async () => {
    try {
      console.log(data);
      if (!data.payment_amount) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);

      console.log({ res });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(defaultPaymentRequestModel);
          setShowSuccess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  const handlePercentageChange = (e: any) => {
    const percentage = Number(e.target.value);

    // Update state immediately for responsive input
    setData((prev) => ({
      ...prev,
      payment_amount: 0,
      percentage: percentage,
    }));

    // Trigger validation and API logic
    getPaymentAmount(percentage);
  };

  const getPaymentAmount = async (percentage: number) => {
    if (percentage < 0) {
      showToast({
        type: ToastType.error,
        text: "Please enter a valid percentage.",
      });
      return;
    }

    try {
      // Fetch the current total percentage from the backend
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-percentage/${data.po_no}`
      );
      const po_total =
        (purchaseOrderData?.subtotal ?? 0) +
          (purchaseOrderData?.tax ?? 0) -
          (purchaseOrderData?.discount ?? 0) +
          (purchaseOrderData?.extra ?? 0) || 0;

      if (res.success) {
        const calculatedPercentage = purchaseOrderData
          ? ((res.totalAmount - payAmount) / po_total) * 100
          : 0;
        const availablePercentage = 100 - calculatedPercentage;
        setPercent(availablePercentage);
        if (percentage > availablePercentage) {
          showToast({
            type: ToastType.error,
            text: `Percentage exceeds available limit. Available: ${availablePercentage}%`,
          });
          return;
        }
        // const availablePercent = 100 - totalPercentageUsed;
        const calculatedAmount = purchaseOrderData
          ? (percentage * po_total) / 100
          : 0;

        if (calculatedAmount > (po_total ?? 0)) {
          showToast({
            type: ToastType.error,
            text: "Amount should not be greater than total amount.",
          });
          return;
        }

        // Update the payment amount in the state
        setData((prev) => ({
          ...prev,
          payment_amount: calculatedAmount,
        }));
      } else {
        showToast({
          type: ToastType.error,
          text: res.message || "Failed to fetch percentage data.",
        });
      }
    } catch (error: any) {
      console.error("Error fetching percentage:", error);
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  const handleAmountChange = (e: any) => {
    const amount = Number(e.target.value);

    // Update state immediately for responsive input
    setData((prev) => ({
      ...prev,
      payment_amount: amount,
      percentage: 0,
    }));

    // Trigger validation and API logic
    getPercentage(amount);
  };

  const getPercentage = async (amount: number) => {
    if (amount < 0) {
      showToast({
        type: ToastType.error,
        text: "Amount cannot be negative.",
      });
      return;
    }

    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-percentage/${data.po_no}`
      );
      if (res.success) {
        const totalAmount = res.totalAmount || 0;
        const po_total =
          (purchaseOrderData?.subtotal ?? 0) +
            (purchaseOrderData?.tax ?? 0) -
            (purchaseOrderData?.discount ?? 0) +
            (purchaseOrderData?.extra ?? 0) || 0;

        const availableAmount = po_total - totalAmount + payAmount;
        // to include this pr pay amount as well in updated amount.
        setAmount(availableAmount);
        if (amount > availableAmount) {
          showToast({
            type: ToastType.error,
            text: `Amount exceeds available limit. Available: ${availableAmount}.`,
          });
          return;
        }
        const calculatedPercentage = purchaseOrderData
          ? (amount / (po_total || 1)) * 100
          : 0;

        if (calculatedPercentage > 100) {
          showToast({
            type: ToastType.error,
            text: "Calculated percentage exceeds 100%.",
          });
          return;
        }

        setData((prev) => ({
          ...prev,
          percentage: calculatedPercentage,
        }));
      } else {
        showToast({
          type: ToastType.error,
          text: res.message || "Failed to fetch amount data.",
        });
      }
    } catch (error: any) {
      console.error("Error fetching amount:", error);
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred.",
      });
    }
  };

  const fetchPurchaseOrderByPoNo = async (
    poNo: string
  ): Promise<PurchaseOrderModel | null> => {
    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${poNo}`
      );
      if (res.success) {
        setPurchaseOrderData(res.data);
        // Assuming res.data is a single PurchaseOrderModel
        console.log("purchaseOrderData", purchaseOrderData);
        return res.data as PurchaseOrderModel;
      } else {
        showToast({ type: ToastType.error, text: res.error });
        return null;
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: (error as Error).message });
      return null;
    }
  };

  const handleCancelPaymentRequest = async () => {
    resetForm();
  };

  const handleUpdatePaymentRequest = async (e: any) => {
    e.preventDefault(); // Prevent form submission and page reload
    // console.log("data.payment_amount",data.payment_amount)
    await getPercentage(data.payment_amount);
    if (data.pr_status === "Approved" || data.pr_status === "Rejected") {
      showToast({
        type: ToastType.error,
        text: "The Approved/Rejected PR is not editable!!",
      });
      handleCancelPaymentRequest();
      return;
    }
    if (amountAdvance < data.payment_amount) {
      showToast({
        type: ToastType.error,
        text:
          "Amount should not be greater than total amount: " + amountAdvance,
      });
      return;
    }
    // Validate if payment amount and due date are provided
    if (!data.payment_amount || !data.percentage || !data.due_date) {
      showToast({
        type: ToastType.error,
        text: "Please enter all values!",
      });
      return;
    }
    if ((data?.pi_amount ?? 0) > 0) {
      if ((data?.pi_amount ?? 0) < data?.payment_amount) {
        showToast({
          type: ToastType.error,
          text: "Payment amount cannot be more than Invoice amount.",
        });
        return;
      }
    }

    setLoading(true); // Start loading indicator

    try {
      // Get pre-signed URLs for file uploads
      const pre_signed_url_list = await getProcurementAttachmentPRESignedURL(
        advancePaymentFileList
      );
      if (!pre_signed_url_list) {
        showToast({
          type: ToastType.error,
          text: "Error in getting signed URL",
        });
        setLoading(false); // Stop loading on error
        return;
      }

      // Iterate over each file and upload it
      for (let i = 0; i < pre_signed_url_list.length; i++) {
        const [signed_url, file, attachment] = pre_signed_url_list[i];
        const res = await postProcurementAttachmentS3(
          signed_url,
          file,
          attachment
        );
        if (!res) {
          showToast({
            type: ToastType.error,
            text: "Error in uploading attachments",
          });
          setLoading(false); // Stop loading on upload failure
          return;
        }
      }

      // Add new attachments to the state
      const newAttachments = pre_signed_url_list.map(
        ([, , attachment]) => attachment
      );
      const updatedState = {
        ...data,
        attachments: [...data.attachments, ...newAttachments],
      };

      // Call the API to create the Advance Payment Request
      const create_payment_request_advance_payment_res =
        await updatePaymentRequestApi(updatedState.id, updatedState);
      setData(updatedState); // Update state with the new data

      if (create_payment_request_advance_payment_res.success) {
        showToast({
          type: ToastType.success,
          text: create_payment_request_advance_payment_res.message,
        });
      } else {
        showToast({
          type: ToastType.error,
          text: create_payment_request_advance_payment_res.error,
        });
      }
    } catch (error: any) {
      // Handle unexpected errors
      console.error(
        "Error in uploading attachments or creating payment request",
        error
      );
      showToast({
        type: ToastType.error,
        text: error.message || "An unexpected error occurred",
      });
    } finally {
      // Ensure loading is stopped regardless of success or failure
      setLoading(false);
      setShowCreatePaymentRequestModal(false); // Close modal after processing
    }
  };

  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const showLog = () => {
    showModal({
      title: "Logs",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => <ActionHistoryAdvance pr_id={data.id} data={data} />,
    });
  };

  const getData = async (id: string) => {
    try {
      setLoading(true);
      console.log("id:", id);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        const updatedData = { ...res.data, type: res.data.type }; // Update type property if necessary
        setData(updatedData); // Update state with merged data
        setPayAmount(updatedData.payment_amount);
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setData(payment_request_default);
    setShowCreatePaymentRequestModal(false);
  };

  return (
    <>
      {showCreatePaymentRequestModal && (
        <DrawerModal
          show={showCreatePaymentRequestModal}
          title={"Update Payment Request"}
          onClose={resetForm}
          location={DrawerModalLoaction.right} // or whatever location you are using for the DrawerModal
        >
          <div className="flex flex-col gap-3 w-auto h-auto p-5">
            {/* Render form based on selected payment type */}
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-5 w-auto">
                {/* First Row */}
                <div className="flex flex-row gap-5 w-full">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      Percentage
                    </label>
                    <input
                      type="number"
                      className="border px-2 py-1 rounded-md w-full"
                      value={data.percentage}
                      onChange={handlePercentageChange}
                      // onChange={(e) =>
                      //   setPaymentRequestAdvance({
                      //     ...paymentRequestAdvance,
                      //     percentage: Number(e.target.value),
                      //   })
                      // }
                    />
                    <span className="font-light">
                      Available Percent is:{percent}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      Payment Amount
                    </label>
                    <input
                      type="number"
                      className="border px-2 py-1 rounded-md w-full"
                      value={data.payment_amount || ""}
                      onChange={handleAmountChange}
                      // disabled={paymentRequestAdvance.percentage !== undefined}
                    />
                    <span className="font-light">
                      Available amount is:{amountAdvance}
                    </span>
                  </div>
                </div>

                {/* Second Row */}
                <div className="flex flex-row gap-5 w-full">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      Due Date
                    </label>
                    <input
                      type="date"
                      value={
                        data?.due_date
                          ? moment(data?.due_date).format("YYYY-MM-DD") // Format to YYYY-MM-DD for input
                          : "" // Empty by default, so no date is selected initially
                      }
                      min={moment().format("YYYY-MM-DD")} // Prevent selection of past dates
                      className="w-full px-3 py-2 border rounded-md"
                      onChange={(e) => {
                        const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                        const date = selectedDate
                          ? new Date(
                              new Date(selectedDate).setHours(0, 0, 0, 0)
                            ) // Set the time to 00:00:00
                          : undefined; // If no date is selected, reset the value

                        // Update the state with the selected date
                        setData((pv) => ({
                          ...pv,
                          due_date: date!, // Save the selected date in state
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      PI Number
                    </label>
                    <input
                      type="text"
                      className="border px-2 py-1 rounded-md w-full"
                      value={data.pi_no}
                      onChange={(e) =>
                        setData({
                          ...data,
                          pi_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                {/* Third Row */}
                <div className="flex flex-row gap-5 w-full">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      PI Date
                    </label>
                    <input
                      type="date"
                      className="border px-7 py-1 rounded-md w-full"
                      value={
                        data?.pi_date
                          ? moment(data?.pi_date).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                        const date = selectedDate
                          ? new Date(
                              new Date(selectedDate).setHours(0, 0, 0, 0)
                            ) // Convert to a date object
                          : undefined; // Reset if no date is selected

                        // Update the paymentRequest state with the selected date
                        setData((pv) => ({
                          ...pv,
                          pi_date: date!, // Save the selected date in state
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="text-xs text-gray-500">
                      PI Amount
                    </label>
                    <input
                      type="number"
                      className="border px-2 py-1 rounded-md w-full"
                      value={data.pi_amount}
                      onChange={(e) =>
                        setData({
                          ...data,
                          pi_amount: +e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className="text-xs text-gray-500">
                  Payment Mode
                </label>
                <select
                  id="payment_mode"
                  className="border px-2 py-1 rounded-md w-full"
                  value={data.payment_mode} // Ensure default is "raw_material"
                  onChange={(e) =>
                    setData({
                      ...data,
                      payment_mode: e.target.value,
                    })
                  }
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {Object.values(PaymentModeOptions).map((c, i) => (
                    <option value={c} key={i}>
                      {c}
                    </option>
                  ))}
                  {/* <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Petty Cash">Petty Cash</option> */}
                  {/* Add more options as needed */}
                </select>
              </div>

              {/* Attach Files Section */}

              <div className="flex flex-col gap-1">
                <label htmlFor="" className="text-xs text-gray-500">
                  Attach Files
                </label>

                <input
                  type="file"
                  multiple
                  className="border px-2 py-1 rounded-md"
                  onChange={(e) => {
                    if (e.target.files) {
                      const newFiles = Array.from(e.target.files);
                      console.log(newFiles);
                      setAdvancePaymentFileList((prevFiles) => [
                        ...prevFiles,
                        ...newFiles,
                      ]);
                      console.log(
                        "advance payment fileList",
                        advancePaymentFileList
                      );
                    }
                  }}
                />

                <div className="w-full mt-2 mx-auto">
                  {/* Render previously uploaded files (data.attachments) */}
                  {data.attachments?.length > 0 && (
                    <div>
                      <h4 className="text-sm font-semibold mb-2">
                        Previous Attachments:
                      </h4>
                      {data.attachments.map((attachment, index) => (
                        <ul
                          key={index}
                          className="py-2 m-2 flex justify-around rounded-md text-black border-2"
                        >
                          <div>
                            <li className="flex items-center gap-2">
                              {/* Check for file type and render accordingly */}
                              {attachment?.type === "Image" ||
                              attachment?.type === "CSV" ? (
                                <img
                                  src={attachment?.url} // Using signed URL for image
                                  alt="Attachment preview"
                                  className="w-16 h-16 object-cover"
                                />
                              ) : attachment?.type === "application/pdf" ? (
                                <embed
                                  src={attachment.url} // Using signed URL for PDF preview
                                  type="application/pdf"
                                  className="w-16 h-16"
                                />
                              ) : (
                                <span>{attachment.url}</span> // For other file types, display the file name or URL
                              )}
                            </li>
                          </div>

                          <div>
                            <button
                              type="button"
                              className="text-red-500"
                              onClick={() => {
                                // Handle removal of the previous attachment
                                const updatedAttachments =
                                  data.attachments.filter(
                                    (_, i) => i !== index
                                  );
                                setData({
                                  ...data,
                                  attachments: updatedAttachments, // Update the data state with the new list
                                });
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </ul>
                      ))}
                    </div>
                  )}

                  {/* Render newly selected files (advancePaymentFileList) */}
                  {advancePaymentFileList.length > 0 && (
                    <div>
                      <h4 className="text-sm font-semibold mb-2">
                        Newly Selected Files:
                      </h4>
                      {advancePaymentFileList.map((file, index) => (
                        <ul
                          key={index}
                          className="py-2 m-2 flex justify-around rounded-md text-black border-2"
                        >
                          <div>
                            <li className="flex items-center gap-2">
                              {/* Check for file type and render accordingly */}
                              {file.type.startsWith("image") ? (
                                <img
                                  src={URL.createObjectURL(file)} // Using object URL for image preview
                                  alt={file.name}
                                  className="w-16 h-16 object-cover"
                                />
                              ) : file.type === "pdf" ? (
                                <embed
                                  src={URL.createObjectURL(file)} // Using object URL for PDF preview
                                  type="application/pdf"
                                  className="w-16 h-16"
                                />
                              ) : file.type === "csv" ? (
                                <embed
                                  src={URL.createObjectURL(file)} // Using object URL for PDF preview
                                  type="CSV"
                                  className="w-16 h-16"
                                />
                              ) : (
                                <span>{file.name}</span> // For other file types, display the file name
                              )}
                            </li>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="text-red-500"
                              onClick={() => {
                                // Handle removal of the newly selected attachment
                                setAdvancePaymentFileList((prevFiles) =>
                                  prevFiles.filter(
                                    (_, currIndex) => currIndex !== index
                                  )
                                );
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Additional Notes Text Area */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="text-xs text-gray-500">
                  Additional Notes
                </label>
                <textarea
                  className="border px-2 py-1 rounded-md"
                  value={data.notes}
                  onChange={(e) =>
                    setData({
                      ...data,
                      notes: e.target.value,
                    })
                  }
                  placeholder="Enter any additional notes here"
                />
              </div>
            </div>

            <div className="flex flex-row gap-4 justify-between mt-5">
              <div className="align-center justify-center flex">
                <button
                  onClick={handleCancelPaymentRequest}
                  className="bg-white px-24 py-2 rounded-md text-black ml-auto border-2"
                >
                  Cancel
                </button>
              </div>
              {/* Create Button */}
              <div className="bg-red-400">
                <button
                  onClick={handleUpdatePaymentRequest}
                  className="bg-myPrimaryColor px-24 py-2 rounded-md text-white ml-auto border-2"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </DrawerModal>
      )}
      {/* <div className="flex items-center justify-between mb-2 py-2 px-6">
        <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Payment Request <span className="text-sm">#{data.id}</span>
        </div>
      </div> */}
      <div className="flex items-center justify-between py-2 px-5 sticky top-0 bg-myBgcolor">
        <div className="flex flex-row">
          <button
            className="  flex  items-center p-2 mt-2 hover:text-gray-700  font-bold text-lg"
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon path={mdiChevronLeft} className="h-6 w-6 font-bold"></Icon>{" "}
            Back
          </button>
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2 mt-4">
            Advance Payment Request <span className="text-sm">#{data.id}</span>
          </div>
        </div>
        {data.id && (
          <button
            title="Logs"
            // to={`${window.document.location.pathname}/log`}
            onClick={showLog}
            className="mx-2 rounded-full p-2 hover:scale-105 transform duration-150    bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon path={mdiHistory} className="h-6 w-6  "></Icon>
          </button>
        )}
      </div>
      <div className="flex flex-col gap-4 mx-10 bg-white rounded-md p-5">
        <div className="flex flex-row justify-between items-center mx-5">
          <h3 className="text-xl text-black font-semibold">
            Advance Payment Request{" "}
          </h3>
          <button
            className="text-white bg-green-500 rounded-md border-2 p-2"
            onClick={() => {
              getData(id);
              setShowCreatePaymentRequestModal(true);
            }}
          >
            Update
          </button>
        </div>
      </div>
      <form onSubmit={submit} className="px-6 py-5">
        {/* <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Advance Payment Request <span className="text-sm">#{data.id}</span>
          </div>
        </div> */}
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
          {/* PR Details Section */}
          <div className="flex flex-col gap-4 ml-5 bg-white rounded-md p-5">
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-xl text-black font-semibold">PR Details </h3>
              {/* <button
                className="text-white bg-green-500 rounded-md border-2 p-2"
                onClick={() => {
                  getData(id);
                  setShowCreatePaymentRequestModal(true);
                }}
              >
                Update
              </button> */}
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              {" "}
              {/* Adjusted flex layout */}
              <div className="flex flex-col w-full sm:w-1/2">
                {" "}
                {/* Adjusted width for better responsiveness */}
                <label htmlFor="payment_amount">Percentage</label>
                <input
                  type="number"
                  disabled
                  className="bg-white"
                  id="payment_amount"
                  name="payment_amount"
                  value={data.percentage}
                />
              </div>
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="payment_date">PO No.</label>
                <input
                  className="text-blue-500 bg-white"
                  type="string"
                  id="po_no"
                  name="po_no"
                  disabled
                  value={`#${data.po_no}`}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  disabled
                  className="bg-white text-green-500"
                  value={data.payment_amount}
                />
              </div>
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="pi_no">PI No.</label>
                <input
                  type="text"
                  id="pi_no"
                  name="pi_no"
                  disabled
                  className="bg-white"
                  value={data.pi_no}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="payment_status">Due date</label>
                <input
                  type="date"
                  id="due_date"
                  name="due_date"
                  className="bg-white"
                  disabled
                  readOnly
                  value={moment(data?.due_date).format("YYYY-MM-DD") || "--"}
                />
              </div>
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="pi_amount">PI Amount</label>
                <input
                  type="text"
                  id="pi_amount"
                  name="pi_amount"
                  className="bg-white"
                  disabled
                  value={data.pi_amount}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="created_on">Created On</label>
                <input
                  type="date"
                  id="created_date"
                  name="created_date"
                  className="bg-white"
                  disabled
                  readOnly
                  value={moment(data?.created_at).format("YYYY-MM-DD") || "--"}
                />
              </div>
              <div className="flex flex-col w-full sm:w-1/2">
                <label htmlFor="pi_date">PI date</label>
                <input
                  type="date"
                  id="pi_date"
                  name="pi_date"
                  className="bg-white"
                  disabled
                  readOnly
                  value={moment(data?.pi_date).format("YYYY-MM-DD") || "--"}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="requested_by">Requested By</label>
              <input
                type="text"
                id="requested_by"
                name="requested_by"
                className="bg-white"
                disabled
                value={data.created_by_name}
              />
            </div>
            <hr />
            <div className="flex flex-col">
              <label htmlFor="notes">Notes</label>
              <textarea
                id="notes"
                name="notes"
                className="bg-white border-2"
                value={data?.notes}
              />
            </div>
          </div>

          {/* Attachments Section */}
          <div className="flex flex-col gap-4 mr-5 bg-white rounded-md p-5">
            <h3 className="text-xl text-black font-semibold">Attachments({data?.attachments?.length})</h3>
            <div className="flex flex-col gap-4">
              {/* Scrollable container for the attachment viewer */}
              <div className="max-h-96 overflow-y-auto border-2 rounded-md">
                <AttachmentViewer attachments={data?.attachments} />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex flex-col gap-4 my-5 ml-5 bg-white rounded-md p-5">
            <div className="col-span-2 flex flex-col gap-2">
              <div className="">
                <label htmlFor="" className="text-sm font-semibold  ">
                  Project
                </label>{" "}
                <ProjectSearchField
                  disabled
                  placeholder="Select Project"
                  value={project}
                  onSelect={(d) => {
                    setProject(d);
                  }}
                />
              </div>

              <div className=" text-sm mt-2">
                <div className="grid grid-cols-4">
                  <h5>Project name</h5>
                  <p className="col-span-3">:{project?.name}</p>
                </div>
                <div className="grid grid-cols-4">
                  <h5>Description</h5>
                  <p className="col-span-3">:{project?.description}</p>
                </div>
                <hr />
                <div className="grid grid-cols-4">
                  <h5>Amount :</h5>
                  <p className="col-span-3  text-right">
                    {currencyFormat(project?.amount || 0)}
                  </p>
                </div>
                <div className="grid grid-cols-4">
                  <h5>Spent :</h5>
                  <p className="col-span-3  text-right">
                    {currencyFormat(project?.spent || 0)}
                  </p>
                </div>
                <hr />
                <div className="grid grid-cols-4">
                  <h5>Balance : </h5>
                  <p className="col-span-3 text-right">
                    {currencyFormat(
                      (project?.amount || 0) - (project?.spent || 0)
                    )}
                  </p>
                </div>

                <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                  <Icon path={mdiInformationOutline} size={0.8}></Icon>Note :
                  this balance is real-time ,Eg: if you create a po on x date
                  and balance is y then if you open the same po on z date
                  balance may be v.
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-col lg:flex-row justify-between lg:justify-between gap-4 my-5 mr-5 bg-white rounded-md p-5">
            <div className="flex flex-col gap-4">
              <div className="mt-5">
                <h3 className="text-xl text-black font-semibold">
                  Vendor Details{" "}
                </h3>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                {" "}
                {/* Changes here */}
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <label htmlFor="vendor">Vendor</label>
                    <input
                      type="string"
                      disabled
                      className="bg-white"
                      id="vendor"
                      name="vendor"
                      value={purchaseOrderData?.party?.name}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="type">Type</label>
                    <input
                      className="text-blue-500 bg-white"
                      type="string"
                      id="type"
                      name="type"
                      disabled
                      value={purchaseOrderData?.party?.type}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <div>
                <h3 className="text-xl text-black font-semibold">
                  Shipping Profile{" "}
                </h3>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                {" "}
                {/* Changes here */}
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <label htmlFor="address">Address</label>
                    <input
                      type="string"
                      disabled
                      className="bg-white"
                      id="address"
                      name="address"
                      value={`${purchaseOrderData?.warehouse?.address?.city}, ${purchaseOrderData?.warehouse?.address?.pincode}`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="contact">Contact</label>
                    <input
                      className="text-blue-500 bg-white"
                      type="string"
                      id="contact"
                      name="contact"
                      disabled
                      value={purchaseOrderData?.warehouse?.spoc?.contact_mobile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gap-4 m-5">
          <h3 className="text-sm font-bold">Items</h3>
          <div
            className="bg-white rounded flex flex-col gap-1 border   "
            // style={{ height: "75vh" }}
          >
            <div className="grid grid-cols-9 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  ">
              <div className="col-span-2">Particular</div>
              <div className="text-center">HSN/SAC</div>
              <div className="text-center">qty</div>
              <div className="text-center">Price</div>
              <div className="text-right">Amount</div>
              <div className="text-center" title="Received qty">
                Received qty
              </div>
              <div className="text-center" title="Returned qty">
                Returned qty
              </div>
              <div className="text-right"> </div>
            </div>
            <div className="flex flex-col gap-1 px-1  text-sm overflow-auto h-72">
              {purchaseOrderData?.items.map((item: any, i) => {
                return (
                  <PoItemRow
                    currency={
                      purchaseOrderData?.vendor_currency || "DEFAULT_CURRENCY"
                    }
                    disabled
                    key={item.id}
                    data={item}
                    onSubmit={() => {}}
                    // onDelete={(d) => {
                    //   setPurchaseOrderData((o) => ({
                    //     ...o,
                    //     items: [...(o.items || [])].filter(
                    //       (i) => i.id !== d.id
                    //     ),
                    //   }));
                    // }}
                    // onSubmit={function (data: PoItem): void {
                    //   setData((o) => ({
                    //     ...o,
                    //     items: [
                    //       ...(o.items || []).map((it) => {
                    //         if (it.id === data.id) return data;
                    //         return it;
                    //       }),
                    //     ],
                    //   }));
                    // }}
                    edit={false}
                    existingItems={existingItems}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="gap-4 m-5 bg-white p-5">
          <div className="flex justify-between font-bold ">
            <p>Total</p>{" "}
            {console.log(
              "short hand",
              purchaseOrderData?.vendor_currency?.short_name
            )}
            <div className="">
              {/* {currencyFormat(purchaseOrderData?.total ?? 0, purchaseOrderData?.vendor_currency?.short_name ?? '')} */}
              {currencyFormat(
                purchaseOrderData?.total ?? 0,
                purchaseOrderData?.vendor_currency?.short_name || "INR"
              )}
            </div>
          </div>
          <hr />
          <div className="flex justify-between font-bold ">
            <p>Paid</p>{" "}
            <div className="">
              {currencyFormat(
                purchaseOrderData?.paid ?? 0,
                purchaseOrderData?.vendor_currency?.short_name || "INR"
              )}
            </div>
          </div>
          <hr />
          <div className="flex justify-between font-bold ">
            <p>Balance</p>{" "}
            <div className="">
              {currencyFormat(
                (purchaseOrderData?.total ?? 0) -
                  (purchaseOrderData?.paid || 0),
                purchaseOrderData?.vendor_currency.short_name || "INR"
              )}
            </div>
          </div>
        </div>

        {/* Assuming you have a LoadingWidget component */}
      </form>

      {loading && <LoadingWidget />}
    </>
  );
};

export default PaymentRequestAdvanceScreen;
